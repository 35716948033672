body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hero .app-logo {
    max-width: 300px;
    width: 300px;
}

img.mb-3.logo-small {
    height: 100%;
    width: 80px;
    margin-bottom: 0px!important;
    margin-right: 60px;
    margin-left: 40px;
}

.row > div {
    width: 15%;
    text-align: center;
}

iframe {
    width: 100%;
    height: 80vh;
}

.p-toast-message-content .flex.gap-2 {
    justify-content: space-evenly;
    width: 100%;
    display: flex;
    margin-top: 40px;
}
