.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

.error {
    text-align: center;
    padding-bottom: 10px;
    font-weight: bold;
    color: red;
    display: none;
}

.error.active {
    display: block;
}

.p-menubar-end button {
    margin-top: 0px!important;
}

.pdf_buttons button {
    margin-top: 0px!important;
    margin-left: 10px;
    margin-right: 10px;
}

.p-toast {
    opacity: 1;
}

/*label.form-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

label.form-label span {
    width: auto;
    margin-right: 10px;
}*/

.dashboard {
    width: 90vw;
}

.card_stats_view {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 50px;
}

.card.chart {
    height: 300px;
}

.card.chart .p-skeleton {
    height: 100%!important;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.p-menubar-start img {
    height: 56px;
}

button.p-button.p-component.p-button-raised {
    float: left;
    margin-top: 20px;
}

input[type=file] {
    height: 58px;
}

input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: grey;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
  
}

input[type=file]::file-selector-button:hover {
  background: #0d45a5;
}

.p-card .p-card-content {
    padding: 1.25rem 0;
    overflow: auto;
}

.p-card-content form {
    float: left;
    width: 100%;
}

.p-menubar.p-component {
    padding: 10px 20px;
}

.p-dropdown.p-component.p-inputwrapper.w-full.md\:w-14rem {
    width: 100%;
    /* margin: 30px 0px 10px; */
}

.p-float-label {
    display: block;
    position: relative;
    margin: 50px 0px 0px;
}

.spinner {
    position: absolute;
    display: flex;
    justify-content: center;
    height: 50vh;
    width: 100vw;
    background-color: white;
    top: 25vh;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000000;
}

.loading {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255,255,255,.8);
    z-index: 1000000;
    display: none;
}

.loading.active {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255,255,255,.8);
    z-index: 1000000;
    display: flex;
}

.flex.justify-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

span.attachementError {
    color: red;
    font-weight: bold;
    padding: 5px 10px 30px;
    width: 100%;
    float: left;
    display: none;
}

span.attachementSuccess.active {
    display: block;
}

span.attachementSuccess {
    color: green;
    font-weight: bold;
    padding: 5px 10px 30px;
    width: 100%;
    float: left;
    display: none;
}

span.attachementError.active {
    display: block;
}

.form_row.section.attachment {
    margin-bottom: 50px;
}

a.PDFDownload {
    width: 250px;
    margin: 30px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: green;
    color: white;
    padding: 10px;
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px;
    border-radius: 10px;
}

.application_row_header.row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.application_row.row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.result-block-container .result-block {
  opacity: 1;
}

.multiInputRow {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 0px;
    border-bottom: 2px solid;
}

.lineitem_row {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    justify-content: space-between;
}

.lineitem_row, .multiInputRow {
    flex-wrap: wrap;
}

label.form-label span {
    width: 100%;
    float: left;
}

.form_row.full label {
    width: 100%;
}

.form_row label span {
    width: 100%;
}

label.medium_input textarea {
    width: 100%;
}

label.medium_input {
    width: 33%;
}

label.large_input {
    width: 70%;
}

label.large_input textarea {
    width: 100%;
}

label.small_input {
    width: 18%;
}

button.addLineButton.btn.btn-success {
    margin-top: 25px;
}

label.small_input input {
    width: 100%;
}

label.small_input label {
    width: 100%;
}

label.small_input.form-label select {
    margin-top: 8px;
}

.react-datepicker__input-container input {
    margin-top: 0px;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    float: left;
}

.formDivider {
    border-top: 5px solid #000000;
    opacity: .1;
    margin: 50px 0px;
    width: 100%;
    border-radius: 5px;
    float: left;
}

.form_row.notFull {
    float: left;
    margin-right: 20px;
    margin-top: 25px;
    width: auto;
}

.btn {
    border-radius: 10px;
}

.section_title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 26px;
    border-bottom: 3px solid black;
    width: auto;
    display: inline;
    float: left;
}

select.form-select {
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

.form_row.submit {
    margin-bottom: 100px;
}

.form_row.full {
    margin-top: 24px;
}

.form_row {
    width: 100%;
    float: left;
    min-height: 1px;
}

button.btn.btn-success {
    float: left;
}

/* label.small_input.form-label div {
    height: 24px;
} */

.row {
    align-items: center;
    justify-content: center;
}

.application_row.row {
    margin-top: 0px;
    margin-bottom: 20px;
    width: 100%;
    float: left;
    padding: 0px 0px 20px;
    border-bottom: 1px solid;

}

.attachementSaved {
    width: 100%;
    float: left;
    margin-bottom: 10px;
    font-weight: bold;
    display: none;
}

.attachementSaved.active {
    width: 100%;
    float: left;
    margin-bottom: 10px;
    font-weight: bold;
    display: block;
}

.application_row_header.row {
    margin-top: 0px;
    margin-bottom: 20px;
    width: 100%;
    float: left;
    padding: 0px 0px 20px;
    border-bottom: 1px solid;
    font-weight: bold;
    font-size: 20px
}

.application_row.row, .application_row_header.row {
    position: relative;
    padding: 0 53px 20px 0px;
}



.row button.hide {
    width: 62px;
    opacity: 0;
}

.job_number, .status, .project_manager {
    text-align: center!important;
}

.status {
    text-transform: capitalize;
}

.status.rejected {
    color: red;
    font-weight: bold;
}

.status.approved {
    color: green;
    font-weight: bold;
}

.status.in_review {
    color: blue;
    font-weight: bold;
}

.status.started {
    color: black;
    font-weight: bold;
}

.status.unsubmitted {
    color: #17a2b8;
    font-weight: bold;
}

.status button.btn-Lg {
    width: 250px;
    margin: 30px 30px;
}

.delete {
    position: absolute;
    right: 0%;
}

label.form-label input {
    margin-top: 8px;
}

.signature {
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,.8);
    top: 0;
    left: 0;
    position: fixed;
    z-index: -1;
    display: none;
}

.signature.active {
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.signature canvas {
    background: white;
    margin-bottom: 20px;
    border: 2px solid black;
}

.signatureInner {
    padding: 50px;
    background: white;
    border-radius: 30px;
}

.flex-grow-1.mt-5.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.button_flex {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

button.w-full.p-link.flex.align-items-center {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 30px 0px 0px;
    width: 200px;
}

.container {
    max-width: 1400px!important;
}

.pm_comments_holder {
    width: 75%;
    border: 2px solid black;
    padding: 20px;
    margin: 20px 0px 50px;
    color: red;
}

.pm_comments_title {
    color: red;
    font-weight: bold;
    font-size: 30px;
}

.total_holder {
    width: 100%;
    float: left;
    margin-top: 50px;
    display: flex;
}

.total_holder span {
    font-weight: bold;
    font-size: 24px;
}

.total_holder div {
    font-size: 24px;
    margin-left: 15px;
}

.total_holder.grand {
    margin-top: -40px;
    margin-bottom: 40px;
}

button.btn.btn-secondary.btn-Lg.success {
    background: green;
}

button.btn.btn-secondary.btn-Lg.edit {
    background: yellow;
    color: black;
}

.application_row_header.row div {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow {
    /* display: none; */
    opacity: 0;
}

.application_row_header.row.active .arrow {
    transform: rotate(180deg);
}

.current .arrow {
    opacity: 1;
}

.error_popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    background: rgba(0,0,0,.5);
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.error_popup.active {
    z-index: 1000;
    opacity: 1;
}

.errortext {
    margin-bottom: 20px;
}


.error_popup_holder {
    width: 400px;
    height: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: white;
    justify-content: center;
    border-radius: 30px;
    font-size: 25px;
    text-align: center;
    padding: 25px;
    box-sizing: border-box;
    transform: translateY(100vh);
    opacity: 0;
    transition: all 1000ms;
    -webkit-transition: all 1000ms;
}

.error_popup.active .error_popup_holder {
    transform: translateY(0vh);
    opacity: 1;
}
